var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.selectedEmail == ""
        ? _c("p", [
            _vm._v("\n    No email address currently set for report "),
            _c(
              "a",
              {
                staticClass: "expand-link",
                on: { click: _vm.expandEmailText },
              },
              [_vm._v("add email address?")]
            ),
          ])
        : _c("p", [
            _vm._v(
              "\n    Your current email address for monthly reports: " +
                _vm._s(_vm.selectedEmail) +
                " "
            ),
            _c(
              "a",
              {
                staticClass: "expand-link",
                on: { click: _vm.expandEmailText },
              },
              [_vm._v("Change email address")]
            ),
          ]),
      _vm.emailExpanded
        ? _c(
            "div",
            [
              _c("p", [_vm._v("Which email should we send reports to?")]),
              _c("b-form-select", {
                attrs: { options: _vm.emailOptions },
                on: { change: _vm.updateEmail },
                model: {
                  value: _vm.selectedEmail,
                  callback: function ($$v) {
                    _vm.selectedEmail = $$v
                  },
                  expression: "selectedEmail",
                },
              }),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.add-email-modal",
                      modifiers: { "add-email-modal": true },
                    },
                  ],
                  staticClass: "mt-2",
                  class: _vm.$mq !== "sm" ? "mr-2 rounded" : "",
                  attrs: { variant: "primary", size: "$mq" },
                  on: {
                    click: function ($event) {
                      return _vm.create("email")
                    },
                  },
                },
                [_vm._v("\n      Add Email\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("email-modal", {
        attrs: { bus: _vm.bus },
        on: { complete: _vm.updateEmail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }