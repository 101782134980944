<template>
  <div>
    <masthead
      id="reports"
      title="Reports"
      :datasource="reports"
      :filter-options="filterOptions"
      :show-search-input="false"
      @changed="searchChanged"
    />

    <b-table :items="filteredReports" :fields="reportsFields" class="reports-table">
      <template #cell(time)="data">
        {{ formatTimestamp(data.item.time) }}
      </template>
      <template #cell(url)="data">
        <div @click="openPdf(data.value, data.item.time)">
          <feather-icon type="file-text" width="30" height="30" class="open-pdf" />
        </div>
      </template>
      <template #cell(csv)="data">
        <div v-if="data.item.csv" @click="downloadCSV(data.item.csv)">
          <feather-icon type="file-text" width="30" height="30" class="open-pdf" />
        </div>
        <div v-else>Not Found</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import Masthead from '@/components/shared/Masthead'
import { getDateFilter } from '@/common/modules/dates'
import FeatherIcon from '@/components/shared/FeatherIcon'
import { saveAs } from 'file-saver'

export default {
  components: { Masthead, FeatherIcon },
  props: {
    reports: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pageSize: 20, // Number of documents per page
      currentPage: 1,
      searchQuery: '',
      searchFilters: '',
      storedReports: null,
    }
  },
  computed: {
    reportsFields() {
      return [
        { key: 'time', label: 'Received', sortable: true },
        { key: 'url', label: 'Report', sortable: false },
        { key: 'csv', label: 'All Results', sortable: false },
      ]
    },
    filteredReports() {
      const hasNoSearchCriteria = !this.searchQuery.length && !this.searchFilters.length
      if (hasNoSearchCriteria) return this.storedReports

      const filterByTimeRange = (item) => {
        if (!this.searchFilters.length) return true
        return this.reportInFilterTimeRange(item.time)
      }

      return this.reports.filter(item => filterByTimeRange(item))
    },
    filterOptions() {
      return {
        dateRadioGroup: {
          filters: [
            getDateFilter('PAST_1_MONTH'),
            getDateFilter('PAST_3_MONTHS'),
            getDateFilter('PAST_6_MONTHS'),
          ],
          custom: true,
          default: getDateFilter('PAST_3_MONTHS'),
        },
      }
    },
  },
  mounted() {
    this.storedReports = this.reports
  },
  methods: {
    async openPdf(reportUrl, reportTime) {
      function formatDate(dateString) {
        const date = new Date(dateString)
        const options = { year: 'numeric', month: 'long' }
        return new Intl.DateTimeFormat('en-US', options).format(date).replace(' ', '')
      }
      const filename = `Monitoring-Report-${formatDate(reportTime)}.pdf`

      try {
        const response = await fetch(reportUrl, {
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
        if (!response.ok) {
          this.makeToast('danger', 'Error', 'Failed to download the report.')
          return
        }

        const arrayBuffer = await response.arrayBuffer()
        const pdfBlob = new Blob([arrayBuffer], { type: 'application/pdf' })

        saveAs(pdfBlob, filename)
      } catch (error) {
        this.makeToast('danger', 'Error', 'Failed to download the report.')
      }
    },
    async downloadCSV(dynamo_csv) {
      const filename = 'Monitoring-Report.csv'
      const blob = new Blob([dynamo_csv], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    },
    searchChanged(searchObject) {
      // Lowercase just because
      this.searchQuery = searchObject.query.toLowerCase()
      this.searchFilters = searchObject.filters
    },
    reportInFilterTimeRange(reportTime) {
      const start = new Date(this.searchFilters[0].value)
      const end = new Date(this.searchFilters[1].value)
      const timestamp = new Date(reportTime)

      return timestamp >= end && timestamp <= start
    },
    formatTimestamp(t) {
      const date = new Date(t)
      const dateStr = date.toISOString().split('T')[0]
      return dateStr
    },
  },
}
</script>

<style scoped>
.document-preview img {
  max-width: 100%;
  height: auto;
}

.pagination button {
  padding: 0.3em 0.5em;
  margin: 0 0.3em;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.reports-table {
  border-collapse: separate;
  border: 1px solid #cccee0;
  border-radius: 0.5em;
  background-color: white;
  width: 100%;
}

.reports-table th,
.reports-table td {
  border: 1px solid #cccee0;
  padding: 0.5em;
}

.reports-table th {
  background-color: #f5f5f5;
}

.reports-table tr:last-child td:first-child {
  border-bottom-left-radius: 0.5em;
}

.reports-table tr:last-child td:last-child {
  border-bottom-right-radius: 0.5em;
}

.reports-table tr:first-child th:first-child {
  border-top-left-radius: 0.5em;
}

.reports-table tr:first-child th:last-child {
  border-top-right-radius: 0.5em;
}


.open-pdf:hover {
  opacity: 60%;
}
</style>
