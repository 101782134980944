<template>
  <div>
    <p v-if="selectedEmail == ''">
      No email address currently set for report <a class="expand-link" @click="expandEmailText">add email address?</a>
    </p>
    <p v-else>
      Your current email address for monthly reports: {{ selectedEmail }} <a class="expand-link" @click="expandEmailText">Change email address</a>
    </p>
    <div v-if="emailExpanded">
      <p>Which email should we send reports to?</p>
      <b-form-select v-model="selectedEmail" :options="emailOptions" @change="updateEmail" />
      <b-button 
        v-b-modal.add-email-modal 
        variant="primary" 
        size="$mq" 
        class="mt-2"
        :class="$mq !== 'sm' ? 'mr-2 rounded' : ''" 
        @click="create('email')"
      >
        Add Email
      </b-button>
    </div>

    <email-modal :bus="bus" @complete="updateEmail" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import EmailModal from '@/components/AccountSettings/EmailModal'

export default {
  name: "MonitoringOverviewEmails",
  components: { EmailModal },
  data () {
    return {
      cancellationExpanded: false,
      emailExpanded: false,
      selectedEmail: '',
      newEmail: {},
      bus: new Vue(),
      emailFields: [
        { key: 'email_address', sortable: false },
        { key: 'kind', sortable: false },
        { key: 'actions', sortable: false, label: '' },
      ],
    }
  },
  computed: {
    ...mapGetters('monitoring', [
      'monitoringEmailAddress',
    ]),
    ...mapGetters('account', ['account', 'emails']),
    emailOptions() {
      const emails = this.emails.map((email) => email.email_address)
      if (this.monitoringEmailAddress) {
        emails.push(this.monitoringEmailAddress)
      }
      return Array.from(new Set(emails)) // remove duplicates
    },
  },
  async mounted() {
    await this.fetchMonitoringEmailAddress()
    this.selectedEmail = this.monitoringEmailAddress || ''
    this.loaded = true
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchMonitoringEmailAddress',
      'updateEmailAddress',
    ]),
    expandEmailText() {
      this.emailExpanded = !this.emailExpanded
    },
    async create(name) {
      this.bus.$emit(name)
    },
    async updateEmail(email) {
      this.selectedEmail = email
      await this.updateEmailAddress(email)
      this.makeToast('primary', 'Success', 'Email updated!')
    },
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
      })
    },
  },
}
</script>

<style>
.expand-link {
  color: rgb(0, 123, 255) !important;
  cursor: pointer;
}
</style>
