var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("masthead", {
        attrs: {
          id: "reports",
          title: "Reports",
          datasource: _vm.reports,
          "filter-options": _vm.filterOptions,
          "show-search-input": false,
        },
        on: { changed: _vm.searchChanged },
      }),
      _c("b-table", {
        staticClass: "reports-table",
        attrs: { items: _vm.filteredReports, fields: _vm.reportsFields },
        scopedSlots: _vm._u([
          {
            key: "cell(time)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.formatTimestamp(data.item.time)) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(url)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.openPdf(data.value, data.item.time)
                      },
                    },
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "open-pdf",
                      attrs: { type: "file-text", width: "30", height: "30" },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(csv)",
            fn: function (data) {
              return [
                data.item.csv
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.downloadCSV(data.item.csv)
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "open-pdf",
                          attrs: {
                            type: "file-text",
                            width: "30",
                            height: "30",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("div", [_vm._v("Not Found")]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }