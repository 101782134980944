var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "monitoring-page" },
    [
      !_vm.loaded
        ? _c("ct-centered-spinner", { attrs: { height: "16.25rem" } })
        : _vm._e(),
      _vm.monitoringServiceActive && _vm.loaded
        ? _c(
            "div",
            { staticClass: "main" },
            [
              _c("monitoring-overview-emails"),
              _vm.monitoringNotificationsSchedules?.notification_schedule
                ?.events?.length > 0
                ? _c("div", [
                    _vm._v(
                      "\n      Next report expected on: " +
                        _vm._s(
                          _vm.curateNextReportDate(
                            _vm.monitoringNotificationsSchedules
                              ?.notification_schedule?.events[0].created_at
                          )
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _vm.monitoringReports?.length
                ? _c(
                    "div",
                    [
                      _c("monitoring-reports", {
                        attrs: { reports: _vm.monitoringReportsFormatted },
                      }),
                    ],
                    1
                  )
                : _c("div", [
                    _c("p", [
                      _vm._v("You have not received any monthly reports."),
                    ]),
                    _c(
                      "p",
                      [
                        _vm._v(
                          "Still need to set up Trademark Monitoring? Head over to the "
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: { name: "monitoring-overview" } } },
                          [_vm._v("Overview")]
                        ),
                        _vm._v(" tab to get started."),
                      ],
                      1
                    ),
                  ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }