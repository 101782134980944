<template>
  <b-container class="monitoring-page">
    <ct-centered-spinner v-if="!loaded" height="16.25rem" />

    <div v-if="monitoringServiceActive && loaded" class="main">
      <monitoring-overview-emails />

      <div v-if="monitoringNotificationsSchedules?.notification_schedule?.events?.length > 0">
        Next report expected on: {{ curateNextReportDate(monitoringNotificationsSchedules?.notification_schedule?.events[0].created_at) }}
      </div>

      <div v-if="monitoringReports?.length">
        <monitoring-reports :reports="monitoringReportsFormatted" />
      </div>
      <div v-else>
        <p>You have not received any monthly reports.</p>
        <p>Still need to set up Trademark Monitoring? Head over to the <router-link
            :to="{ name: 'monitoring-overview' }">Overview</router-link> tab to get started.</p>
      </div>
    </div>
  </b-container>
</template>

<script>
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import MonitoringReports from '@/components/Monitoring/MonitoringReports.vue'
import MonitoringOverviewEmails from '@/components/Monitoring/MonitoringOverviewEmails.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MonitoringReportsPage',
  components: {
    CtCenteredSpinner,
    MonitoringReports,
    MonitoringOverviewEmails,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('monitoring', [
      'monitoringServiceActive',
      'monitoringReports',
      'monitoringNotificationsSchedules',
    ]),
    ...mapGetters('account', ['account', 'emails', 'actualAccountID']),
    monitoringReportsFormatted() {
      return this.monitoringReports.map(report => {
        return {
          time: report.s3_info.time,
          url: report.s3_info.url,
          csv: report.dynamo_csv,
        }
      })
    },
  },
  async mounted() {
    this.loaded = false
    // Load companies or buckets as needed
    await this.fetchMonitoringNotifcationsSchedule(this.actualAccountID)
    if (this.monitoringServiceActive) await this.fetchReports()
    this.loaded = true
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchMonitoringReports',
      'fetchMonitoringNotifcationsSchedule',
    ]),
    async fetchReports() {
      await this.fetchMonitoringReports(this.actualAccountID)
    },
    curateNextReportDate(scheduledDate) {
      return new Date(new Date(scheduledDate).setDate(new Date(scheduledDate).getDate() + 30)).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
  },
}
</script>
